<template>
  <v-card :outlined="$vuetify.theme.dark" rounded="xl">
    <v-card-title class="d-flex justify-space-between align-center">
      <div class="text-body-1 font-weight-medium">Message To Client</div>
      <v-btn icon @click="getMessageList({ reset: true })">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-card-text
      :style="{ height: `${chatBoxHeight}px` }"
      style="overflow-y: scroll; position: relative"
      ref="chatBox"
    >
      <v-slide-y-transition>
        <div
          class="mb-2 d-flex justify-center"
          v-if="page_number !== page_total"
        >
          <v-btn
            color="primary"
            small
            class="text-capitalize"
            @click="loadMore"
            :loading="loadMoreLoading"
          >
            <v-icon left small>mdi-arrow-up</v-icon>load more
          </v-btn>
        </div>
      </v-slide-y-transition>

      <!-- loading -->
      <div
        v-if="loading"
        class="d-flex justify-center align-center"
        style="height: 100%"
      >
        <v-progress-circular
          :size="40"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>

      <!-- message list -->
      <div v-else style="height: 100%">
        <div v-if="messageList.length" style="height: 100%">
          <div
            v-for="(messages, key, index) in computedMessages"
            :key="key + index"
          >
            <div class="d-flex justify-center my-3">
              <v-chip small outlined>
                {{ key }}
              </v-chip>
            </div>
            <div
              v-for="(message, index) in messages"
              :key="'message' + message.id + index"
            >
              <!-- sender -->
              <div
                class="d-flex justify-end mb-3"
                v-if="message.sender === $keys.TEAM"
              >
                <ChatMessage :message="message" type="sender" />
              </div>

              <!-- receiver -->
              <div class="d-flex justify-start mb-3" v-else>
                <ChatMessage :message="message" type="receiver" />
              </div>
            </div>
          </div>
        </div>
        <!-- empty message -->
        <div
          v-else
          class="d-flex justify-center align-center"
          style="height: 100%"
        >
          No message yet...
        </div>
      </div>
    </v-card-text>

    <div class="pa-3">
      <v-text-field
        v-model="form.text"
        dense
        rounded
        outlined
        hide-details
        height="40px"
        type="text"
        placeholder="Type your message here"
        @keydown.enter.prevent="sendMessage"
      >
        <template v-slot:append>
          <v-progress-circular
            v-if="submitBtnLoading"
            size="24"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-icon
            v-else
            color="primary"
            :disabled="!computedText"
            @click="sendMessage"
          >
            mdi-send
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import ChatMessage from "@/components/CaseManagement/ChatMessage";
export default {
  components: { ChatMessage },
  props: {
    caseDetail: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      chatBoxHeight: 449,

      loading: false,
      messageList: [],

      page_total: 1,
      page_number: 1,
      loadMoreLoading: false,

      form: {
        text: "",
      },
      submitBtnLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "getUser",
    }),

    computedMessages() {
      const reversedMessages = [...this.messageList].reverse();

      return this.$_(reversedMessages)
        .groupBy((message) =>
          this.$moment(message.created).format("DD MMM YYYY")
        )
        .value();
    },

    computedText() {
      return this.form.text.trim();
    },
  },

  async mounted() {
    await this.getMessageList();
  },

  methods: {
    scrollToBottom() {
      const elem = this.$refs.chatBox;
      elem.scrollTo({ top: elem.scrollHeight, behavior: "smooth" });
    },

    scrollToTop(height) {
      const elem = this.$refs.chatBox;
      elem.scrollTo({ top: height ? height : 0, behavior: "smooth" });
    },

    getMessageList({ reset = false, loadMore = false } = {}) {
      if (!loadMore) {
        this.loading = true;
      }

      const onSuccess = (res) => {
        const data = res.data.data;
        const page_info = res.data.page_info;

        this.page_total = page_info.page_total;
        this.page_number = page_info.page_number;

        if (reset) {
          this.messageList = data;
        } else {
          this.messageList = [...this.messageList, ...data];
        }

        if (!loadMore) {
          this.loading = false;
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      };

      const params = {
        case_id: this.caseDetail.id,
        message_group: this.$keys.CLIENT_TEAM,
        page_number: this.page_number,
      };

      if (reset) {
        params.page_number = 1;
      }

      return this.$request(this.$keys.GET, this.$urls.case.message.list, {
        params,
        onSuccess,
      });
    },

    async loadMore() {
      const elem = this.$refs.chatBox;
      const previous_height = elem.scrollHeight;

      this.page_number++;
      this.loadMoreLoading = true;
      await this.getMessageList({ loadMore: true });
      this.loadMoreLoading = false;

      const current_height = elem.scrollHeight;
      const height =
        current_height - previous_height - (this.chatBoxHeight - 40);
      this.scrollToTop(height);
    },

    sendMessage() {
      if (this.computedText) {
        this.submitBtnLoading = true;

        const onSuccess = (res) => {
          const newMsg = [res.data.data];
          this.messageList = [...newMsg, ...this.messageList];

          this.$nextTick(() => {
            this.scrollToBottom();
          });
        };

        const onFinally = () => {
          this.submitBtnLoading = false;
          this.form.text = "";
        };

        const data = {
          case: this.caseDetail.id,
          group: this.$keys.CLIENT_TEAM,
          text: this.form.text,
        };

        return this.$request(
          this.$keys.POST,
          this.$urls.case.message.create,
          {
            data,
            onSuccess,
            onFinally,
          }
        );
      }
    },
  },
};
</script>