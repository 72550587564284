var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"540px","content-class":"rounded-xl"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"outlined":_vm.$vuetify.theme.dark,"rounded":"xl"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"title"},[_vm._v("Request to change the Internal TAT")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.requestTatModal({
            show: false,
            id: '',
            type: '',
          })}}},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('v-card-text',[(_vm.case_basic_info.client)?_c('div',{staticClass:"d-flex justify-space-between my-5"},[_c('div',{staticClass:"text-start"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.case_basic_info.client.name)+" ")]),_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.case_basic_info.client.user.mobile_display)+" | "+_vm._s(_vm.case_basic_info.client.user.email)+" ")])]),_c('div',{staticClass:"text-end"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Case Id")]),_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.case_basic_info.case_id)+" ")])])]):_vm._e(),_c('v-form',{ref:"requestTatForm",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"No. of days of extension","rules":[_vm.$rules.required],"height":"42px","type":"number","flat":"","outlined":"","dense":""},on:{"keydown":_vm.$rules.blockInvalidChar},model:{value:(_vm.days_extension),callback:function ($$v) {_vm.days_extension=$$v},expression:"days_extension"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"reason-title pb-2"},[_vm._v("Internal Reason")]),_c('v-select',{attrs:{"menu-props":{
                bottom: true,
                offsetY: true,
              },"append-icon":"mdi-chevron-down","items":_vm.internal_reason_list,"rules":[_vm.$rules.required],"item-text":"title","item-value":"id","flat":"","outlined":"","dense":"","label":"Select Reason"},model:{value:(_vm.internal_reason),callback:function ($$v) {_vm.internal_reason=$$v},expression:"internal_reason"}})],1),(_vm.internal_reason === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"flat":"","rows":3,"outlined":"","rules":[_vm.$rules.required],"label":"Type here..."},model:{value:(_vm.internal_reason_description),callback:function ($$v) {_vm.internal_reason_description=$$v},expression:"internal_reason_description"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"reason-title pb-2"},[_vm._v("Reason for Client")]),_c('v-select',{attrs:{"menu-props":{
                bottom: true,
                offsetY: true,
              },"append-icon":"mdi-chevron-down","items":_vm.client_reason_list,"rules":[_vm.$rules.required],"item-text":"title","item-value":"id","flat":"","outlined":"","dense":"","label":"Select Reason"},model:{value:(_vm.client_reason),callback:function ($$v) {_vm.client_reason=$$v},expression:"client_reason"}})],1),(_vm.client_reason === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"flat":"","rows":3,"rules":[_vm.$rules.required],"outlined":"","label":"Type here..."},model:{value:(_vm.client_reason_description),callback:function ($$v) {_vm.client_reason_description=$$v},expression:"client_reason_description"}})],1):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize px-8 my-2",attrs:{"loading":_vm.btnLoading,"color":"primary","height":"40px"},on:{"click":_vm.submit}},[_c('span',[_vm._v("Submit")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }