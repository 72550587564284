<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="540px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="title">Request to change the Internal TAT</div>
        <v-btn
          icon
          @click="
            requestTatModal({
              show: false,
              id: '',
              type: '',
            })
          "
        >
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div
          class="d-flex justify-space-between my-5"
          v-if="case_basic_info.client"
        >
          <div class="text-start">
            <div class="font-weight-bold">
              {{ case_basic_info.client.name }}
            </div>
            <div class="text--secondary">
              {{ case_basic_info.client.user.mobile_display }} |
              {{ case_basic_info.client.user.email }}
            </div>
          </div>

          <div class="text-end">
            <div class="font-weight-bold">Case Id</div>
            <div class="text--secondary">
              {{ case_basic_info.case_id }}
            </div>
          </div>
        </div>
        <v-form lazy-validation ref="requestTatForm">
          <v-row no-gutters class="mt-3">
            <v-col cols="12">
              <v-text-field
                v-model="days_extension"
                label="No. of days of extension"
                :rules="[$rules.required]"
                height="42px"
                type="number"
                flat
                outlined
                dense
                @keydown="$rules.blockInvalidChar"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="reason-title pb-2">Internal Reason</div>
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="internal_reason"
                :items="internal_reason_list"
                :rules="[$rules.required]"
                item-text="title"
                item-value="id"
                flat
                outlined
                dense
                label="Select Reason"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="internal_reason === 0">
              <v-textarea
                flat
                :rows="3"
                outlined
                :rules="[$rules.required]"
                v-model="internal_reason_description"
                label="Type here..."
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <div class="reason-title pb-2">Reason for Client</div>
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="client_reason"
                :items="client_reason_list"
                :rules="[$rules.required]"
                item-text="title"
                item-value="id"
                flat
                outlined
                dense
                label="Select Reason"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="client_reason === 0">
              <v-textarea
                flat
                :rows="3"
                :rules="[$rules.required]"
                outlined
                v-model="client_reason_description"
                label="Type here..."
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          :loading="btnLoading"
          color="primary"
          class="text-capitalize px-8 my-2"
          height="40px"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "requestTat",
  data() {
    return {
      case_basic_info: {},
      days_extension: null,
      internal_reason: "",
      internal_reason_list: [],
      internal_reason_description: "",
      client_reason: "",
      client_reason_list: [],
      client_reason_description: "",
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getRequestTatModal: "caseManagement/getRequestTatModal",
    }),
    show: {
      get() {
        return this.getRequestTatModal.show;
      },
      set(value) {
        this.requestTatModal({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      requestTatModal: "caseManagement/requestTatModal",
      showSnackbar: "snackBar/showSnackbar",
    }),
    openModal() {
      this.case_basic_info = this.getRequestTatModal.item;
      this.getClientReasonList();
      this.getInternalReasonList();
    },
    modalClosed() {
      this.$refs.requestTatForm.reset();
      this.days_extension = null;
      this.internal_reason = "";
      this.internal_reason_list = [];
      this.internal_reason_description = "";
      this.client_reason = "";
      this.client_reason_list = [];
      this.client_reason_description = "";
    },
    getInternalReasonList() {
      const onSuccess = (res) => {
        this.internal_reason_list = res.data.data;
        this.internal_reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      let params = {};
      params["reason_type"] = "internal_tat";

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },
    getClientReasonList() {
      const onSuccess = (res) => {
        this.client_reason_list = res.data.data;
        this.client_reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      let params = {};
      params["reason_type"] = "client_tat";

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },
    submit() {
      if (this.$refs.requestTatForm.validate()) {
        this.btnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.requestTatModal({
            show: false,
            id: "",
            type: "",
          });
          this.$emit("reload-case-detail");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        let data = {};
        data["days_extension"] = this.days_extension;
        if (this.internal_reason == 0) {
          data["internal_reason_other"] = this.internal_reason_description;
        } else {
          data["internal_reason"] = this.internal_reason;
        }
        if (this.client_reason == 0) {
          data["client_reason_other"] = this.client_reason_description;
        } else {
          data["client_reason"] = this.client_reason;
        }

        let params = {};
        params["case_id"] = this.getRequestTatModal.item.id;

        return this.$request(
          this.$keys.POST,
          this.$urls.case.tatChangeRequest.create,
          {
            params,
            data,
            onSuccess,
            onFinally,
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.reason-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}
.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
</style>
