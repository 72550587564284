<template>
  <v-data-table
    :loading="loading"
    class="rounded-xl"
    loading-text="Please wait ....Loading"
    :headers="headers"
    :items="items"
    hide-default-footer
    mobile-breakpoint
  >
    <template v-slot:[`item.created`]="{ item }">
      {{ $utils.getDateFormat(item.created) }}
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <div class="py-4">
        <span v-if="item.is_splitted">
          <div>FE/IR: ₹{{ item.fe_ir_amount }}</div>
          <div>University: ₹{{ item.university_amount }}</div>
          <div>Total: ₹{{ item.amount }}</div>
        </span>
        <span v-else>₹{{ item.amount }}</span>
      </div>
    </template>

    <template v-slot:[`item.remarks`]="{ item }">
      <div class="py-4" v-if="item.remarks">
        {{ item.remarks }}
      </div>
      <div v-else>
        <v-icon>mdi-minus</v-icon>
      </div>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        small
        class="text-capitalize lighten-5 text--darken-2 font-weight-bold"
        :class="
          item.status == 'successful'
            ? 'success success--text'
            : item.status == 'pending'
            ? 'blue blue--text'
            : 'error error--text'
        "
      >
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:[`item.action`]="{ item }">
        <div class="my-4">
          <div
            class="d-flex justify-center"
            style="gap: 10px"
            v-if="
              item.actions.includes('payout_receipt_download')
            "
          >
            <v-btn
              small
              outlined
              rounded
              color="primary"
              class="text-capitalize"
              height="32px"
              v-if="item.actions.includes('payout_receipt_download')"
              @click="downloadReceipt(item.id)"
            >
              <v-icon left>mdi-download</v-icon>
              Download Receipt
            </v-btn>
          </div>
          <div v-else>
            <v-icon>mdi-minus</v-icon>
          </div>
        </div>
      </template>
  </v-data-table>
</template>

<script>
import { saveAs } from "file-saver";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },

  props: {
    caseId: Number,
    headers: Array,
  },

  mounted() {
    this.getPayoutList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
    }),
    getPayoutList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        case_id: this.caseId,
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.case.payout.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
    downloadReceipt(payout_id) {
      this.showSnackbar({
        text: "Please wait, download is in progress.",
        color: "success",
      });

      const params = {
        payout_id: payout_id,
      };

      const onSuccess = (res) => {
        let filename = "receipt.pdf";
        if (res.headers["content-disposition"])
          [, filename] = res.headers["content-disposition"].split("filename=");
        saveAs(res.data, filename);
      };

      const onFailure = () => {
        this.showSnackbar({
          text: "Something went wrong",
          color: "error",
        });
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.case.payout.downloadReceipt,
        {
          params,
          onSuccess,
          onFailure,
          responseType: "blob",
        }
      );
    },
    
  },
};
</script>