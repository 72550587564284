<template>
  <v-data-table
    :loading="loading"
    class="rounded-xl"
    loading-text="Please wait ....Loading"
    :headers="headers"
    :items="items"
    hide-default-footer
    mobile-breakpoint
  >
    <template v-slot:[`item.date`]="{ item }">
      {{ $utils.getDateFormat(item.status_datetime) }}
    </template>

    <template v-slot:[`item.case_id`]="{ item }">
      {{ item.case.case_id }}
    </template>

    <template v-slot:[`item.client`]="{ item }">
      <div>
        {{ item.case.client.name }}
        <div v-if="item.case.client.email">{{ item.case.client.email }}</div>
        <div v-if="item.case.client.mobile">
          {{ item.case.client.mobile_display }}
        </div>
      </div>
    </template>

    <template v-slot:[`item.clause`]="{ item }">
      <div v-if="item.clause">
        {{ item.clause.name }}
      </div>
      Refund Percentage: {{ item.refund_percent }}%
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <div>University: ₹{{ item.university_cost }}</div>
      <div>Processing: ₹{{ item.processing_cost }}</div>
      <div>Shipping: ₹{{ item.shipping_cost }}</div>
      <div>Total: ₹{{ item.total_refund_amount }}</div>
    </template>

    <template v-slot:[`item.remarks`]="{ item }">
      <div v-if="item.remarks">
        {{ item.remarks }}
      </div>
      <v-icon v-else>mdi-minus</v-icon>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip small class="text-capitalize font-weight-bold">
        {{ item.status_display }}
      </v-chip>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <div
        class="d-flex justify-center"
        style="gap: 10px"
        v-if="
          item.actions.includes('refund_edit') ||
          item.actions.includes('refund_delete')
        "
      >
        <v-btn
          small
          rounded
          outlined
          color="primary"
          class="text-capitalize"
          v-if="item.actions.includes('refund_edit')"
          @click="editRefund(item)"
        >
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>

        <v-btn
          small
          rounded
          outlined
          color="primary"
          class="text-capitalize"
          v-if="item.actions.includes('refund_delete')"
          @click="deleteRefund(item)"
        >
          <v-icon left>mdi-delete</v-icon> Delete
        </v-btn>
      </div>
      <v-icon v-else>mdi-minus</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },
    caseId: {
      type: Number,
      required: false,
    },
  },

  mounted() {
    this.getRefundList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      toggleUpdateRefundDialog: "refund/toggleAddUpdateDialog",
    }),

    getRefundList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        case_id: this.caseId,
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.case.refund.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    editRefund(item) {
      this.toggleUpdateRefundDialog({
        show: true,
        type: this.$keys.EDIT,
        id: item.id,
        caseId: item.case.id,
      });
    },

    deleteRefund(item) {
      const onSuccess = (res) => {
        this.showSnackbar({ text: res.data.message, color: "success" });
        this.getRefundList();
      };

      const params = {
        refund_id: item.id,
      };

      return this.$request(this.$keys.DELETE, this.$urls.case.refund.delete, {
        params,
        onSuccess,
      });
    },
  },
};
</script>