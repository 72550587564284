<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="900px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="rounded-xl"
    >
      <v-card min-height="350px" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div class="text-h6">TAT Change History</div>

          <v-btn icon @click="modalClosed">
            <v-icon size="22">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            :loading="loading"
            loading-text="Please wait.... Loading"
            :headers="headers"
            :items="items"
            hide-default-footer
            mobile-breakpoint
          >
            <template v-slot:[`item.status_display`]="{ item }">
              {{ item.status_display }}
            </template>

            <template v-slot:[`item.tat_reason`]="{ item }">
              <div>
                <span class="text-body-2 text--secondary">
                  Client Reason :
                </span>
                {{ item.client_reason }}
              </div>
              <div>
                <span class="text-body-2 text--secondary">
                  Internal Reason :
                </span>
                {{ item.internal_reason }}
              </div>
            </template>

            <template v-slot:[`item.days_extension`]="{ item }">
              {{ item.days_extension }}
              {{ item.days_extension > 1 ? "Days" : "Day" }}
            </template>
          </v-data-table>
          <v-divider />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      tatHistoryDialog: "dialogs/tatHistoryDialog",
    }),
    show: {
      get() {
        return this.tatHistoryDialog.show;
      },
      set(value) {
        this.toggleTatHistoryDialog({ show: value });
      },
    },
    headers() {
      return [
        {
          sortable: false,
          text: "No. of days of extension",
          value: "days_extension",
          width: 150,
        },
        {
          sortable: false,
          text: "Reason",
          value: "tat_reason",
          width: 150,
        },
        {
          sortable: false,
          text: "Status",
          value: "status_display",
          width: 150,
        },
      ];
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      toggleTatHistoryDialog: "dialogs/toggleTatHistoryDialog",
      showSnackbar: "snackBar/showSnackbar",
    }),

    async openModal() {
      this.loading = true;
      await this.getItems();
      this.loading = false;
    },

    modalClosed() {
      this.loading = true;
      this.items = [];
      this.toggleTatHistoryDialog({ show: false });
      this.loading = false;
    },

    getItems() {
      const onSuccess = (res) => {
        this.items = res.data.data;
      };

      let params = {
        case_id: this.tatHistoryDialog.caseId,
        apply_pagination: false,
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.case.tatChangeRequest.list,
        {
          params,
          onSuccess,
        }
      );
    },
  },
};
</script>
