<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
  >
    <!-- loader -->
    <Loading v-if="loading" />

    <!-- dialog -->
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <!-- dialog title -->
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">
          <div v-if="type === $keys.ADD">Initiate Refund</div>
          <div v-if="type === $keys.EDIT">Edit Refund</div>
        </div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <!-- dialog content -->
      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <div
            class="d-flex align-center justify-space-between"
            style="gap: 10px"
          >
            <div style="width: 50%">
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                :rules="[$rules.required]"
                v-model="form.clause"
                :items="clauseList"
                item-text="name"
                item-value="id"
                return-object
                flat
                outlined
                dense
                label="Refund Clause"
                persistent-hint
              >
              </v-select>
            </div>

            <v-text-field
              v-model="form.refund_percent"
              @keydown="$rules.blockInvalidChar"
              type="number"
              outlined
              dense
              label="Refund Percentage"
              hide-spin-buttons
              suffix="%"
              :disabled="!form.clause"
              :readonly="form.clause && form.clause.id != 0"
            >
            </v-text-field>
          </div>

          <v-text-field
            v-model="form.university_cost"
            :rules="[
              $rules.validateNegativeNumber,
              validateMaxNumber('university_cost'),
            ]"
            @keydown="$rules.blockInvalidChar"
            @input="validateNegativeNumber('university_cost')"
            type="number"
            outlined
            dense
            label="University Cost"
            hide-spin-buttons
          ></v-text-field>

          <v-text-field
            v-model="form.processing_cost"
            :rules="[
              $rules.validateNegativeNumber,
              validateMaxNumber('processing_cost'),
            ]"
            @keydown="$rules.blockInvalidChar"
            @input="validateNegativeNumber('processing_cost')"
            type="number"
            outlined
            dense
            label="Processing Cost"
            hide-spin-buttons
          ></v-text-field>

          <v-text-field
            v-model="form.shipping_cost"
            :rules="[
              $rules.validateNegativeNumber,
              validateMaxNumber('shipping_cost'),
            ]"
            @keydown="$rules.blockInvalidChar"
            @input="validateNegativeNumber('shipping_cost')"
            type="number"
            outlined
            dense
            label="Shipping Cost"
            hide-spin-buttons
          ></v-text-field>

          <v-textarea
            rows="3"
            type="text"
            v-model="form.remarks"
            flat
            outlined
            dense
            label="Type remarks here..."
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider />

      <!-- modal actions -->
      <v-card-actions class="my-2">
        <div>Total Refundable Amount: ₹{{ total_refundable_amount }}</div>
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-8"
          :loading="btnLoading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,

      caseDetail: null,
      refundDetail: null,
      clauseList: [],

      form: {
        clause: null,
        refund_percent: 0,
        university_cost: 0,
        processing_cost: 0,
        shipping_cost: 0,
        remarks: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      addUpdateDialog: "refund/addUpdateDialog",
    }),

    show: {
      get() {
        return this.addUpdateDialog.show;
      },
      set(value) {
        this.toggleAddUpdateDialog({ show: value });
      },
    },

    type() {
      return this.addUpdateDialog.type;
    },

    total_refundable_amount() {
      return (
        Number(this.form.university_cost) +
        Number(this.form.processing_cost) +
        Number(this.form.shipping_cost)
      );
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    "form.clause"(value) {
      if (value && this.type === this.$keys.ADD) {
        this.form.refund_percent = value.refund_percent;
        this.form.processing_cost =
          Number(this.caseDetail.total_processing_cost) -
          Number(
            (Number(this.caseDetail.total_processing_cost) *
              Number(value.refund_percent)) /
              100
          );
      }
    },

    "form.refund_percent"(value) {
      if (value && this.type === this.$keys.ADD) {
        this.form.processing_cost =
          Number(this.caseDetail.total_processing_cost) -
          Number(
            (Number(this.caseDetail.total_processing_cost) * Number(value)) /
              100
          );
      }
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      toggleAddUpdateDialog: "refund/toggleAddUpdateDialog",
    }),

    async openModal() {
      this.loading = true;

      await this.getCaseDetail();
      await this.getRefundClauseList();

      if (this.type === this.$keys.ADD) {
        this.form.university_cost = this.caseDetail.refundable_university_cost;
        this.form.shipping_cost = this.caseDetail.refundable_shipping_cost;
      }

      if (this.type === this.$keys.EDIT) {
        await this.getRefundDetail();
        
        this.form.clause = this.refundDetail.clause
          ? this.refundDetail.clause
          : this.clauseList.find((clause) => {
              return clause.id === 0;
            });

        this.form.refund_percent = this.refundDetail.refund_percent;
        this.form.university_cost = this.refundDetail.university_cost;
        this.form.processing_cost = this.refundDetail.processing_cost;
        this.form.shipping_cost = this.refundDetail.shipping_cost;
        this.form.remarks = this.refundDetail.remarks;
      }

      this.loading = false;
    },

    modalClosed() {
      this.toggleAddUpdateDialog({ show: false });
      this.$refs.form.reset();
    },

    validateNegativeNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (value < 0) {
        this.form[field] = Number(0);
      } else this.form[field] = value;
    },

    validateMaxNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (this.caseDetail) {
        const data = this.caseDetail;
        if (field === "university_cost") {
          if (value > data.refundable_university_cost)
            return `Refundable University Cost cannot be greater than ${data.refundable_university_cost}`;
        }
        if (field === "processing_cost") {
          if (value > data.total_processing_cost)
            return `Refundable Processing Cost cannot be greater than ${data.total_processing_cost}`;
        }
        if (field === "shipping_cost") {
          if (value > data.refundable_shipping_cost)
            return `Refundable Shipping Cost cannot be greater than ${data.refundable_shipping_cost}`;
        }
      }
      return true;
    },

    getRefundClauseList() {
      const params = {
        apply_pagination: false,
      };

      const onSuccess = (res) => {
        this.clauseList = res.data.data;
        this.clauseList.push({
          id: 0,
          is_active: true,
          name: "Special Clause",
          refund_percent: 0,
        });
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.master.refundClause.list,
        {
          params,
          onSuccess,
        }
      );
    },

    getCaseDetail() {
      const params = {
        case_id: this.addUpdateDialog.caseId,
        query:
          "{ id, total_processing_cost, refundable_university_cost, refundable_shipping_cost }",
      };

      const onSuccess = (res) => {
        this.caseDetail = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
      });
    },

    getRefundDetail() {
      const params = {
        refund_id: this.addUpdateDialog.id,
      };

      const onSuccess = (res) => {
        this.refundDetail = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.case.refund.detail, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        if (this.total_refundable_amount <= 0) {
          this.showSnackbar({
            text: "Can not request without amount.",
            color: "error",
          });
          this.btnLoading = false;
          return;
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = {
          case: this.addUpdateDialog.caseId,
          clause: this.form.clause.id != 0 ? this.form.clause.id : null,
          refund_percent: this.form.refund_percent,
          university_cost: this.form.university_cost,
          processing_cost: this.form.processing_cost,
          shipping_cost: this.form.shipping_cost,
          remarks: this.form.remarks,
        };

        if (this.type === this.$keys.ADD) {
          return this.$request(this.$keys.POST, this.$urls.case.refund.create, {
            data,
            onSuccess,
            onFinally,
          });
        }

        if (this.type === this.$keys.EDIT) {
          const params = {
            refund_id: this.addUpdateDialog.id,
          };

          return this.$request(
            this.$keys.PATCH,
            this.$urls.case.refund.update,
            {
              params,
              data,
              onSuccess,
              onFinally,
            }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
</style>